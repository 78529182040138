import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "../admin/login/login.css";

import { Helmet } from "react-helmet";
import { signImSchema } from "../../schema";
import RegisterService from "../../services/register.service";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../Redux";

export default function RegisterLogin() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { rsaAccessToken, rsa_id } = state;

  useEffect(() => {
    if ((rsaAccessToken, rsa_id)) {
      navigate("/register", { replace: true });
    } else {
      setShow(true);
    }
  }, [rsaAccessToken, rsa_id]);

  const loginCheck = async (values) => {
    setLoading(true);
    try {
      const response = await RegisterService.loginRsa(values);

      if (response.status === 200) {
        await userActions.logIn(
          {
            rsaAccessToken: response.data.data.access_token,
            rsa_id: response.data.data.userId,
          },
          "rsaLogin"
        );
      }
      navigate("/register", { replace: true });
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err?.response?.status === 401) {
        toast.error("Username or Password is invalid!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: signImSchema,
    onSubmit: (values, action) => {
      loginCheck(values);
    },
  });

  return show ? (
    <div className="login-main">
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div className="container">
        <div className="box-outer">
          <div className="login-box">
            <div className="mws-login-lock">
              <i className="bi bi-lock-fill"></i>
            </div>
            <div className="col-lg-12 login-key">
              <img
                src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1718344862/logo_l667oi.png"
                alt=""
              />
            </div>

            <div className="col-lg-12 login-form">
              <div className="col-lg-12 login-form">
                {(errors.username && touched.username) ||
                (errors.password && touched.password) ? (
                  <div className="alert border-0 alert-danger alert-dismissible fade show  py-2">
                    <div className="d-flex align-items-center">
                      <div className="text-danger">
                        Username or Password is required!
                      </div>
                    </div>
                  </div>
                ) : null}

                <form onSubmit={handleSubmit} noValidate>
                  <div className="form-group">
                    <label className="form-control-label">USERNAME</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="username"
                      value={values.username || ""}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">PASSWORD</label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      name="password"
                      value={values.password || ""}
                    />
                  </div>

                  <div className="col-lg-12 loginbttm text-center">
                    <button
                      type="submit"
                      className="btn btn-outline-primary mb-3"
                    >
                      LOGIN
                    </button>
                    <br />
                    <Link to="/register-forgot" className="text-white">
                      Forgot Password?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  ) : (
    <div className="fix-white"></div>
  );
}
