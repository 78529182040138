import { React, useState } from "react";
import { Button, Modal } from "react-bootstrap";

function ContentPopup() {
  const [show, setShow] = useState(true);

  const handleModal = (e) => {
    setShow(!show);
  };

  return (
    <div>
      <div>
        <Modal
          show={show}
          onHide={handleModal}
          id="modelContent"
          size="xl"
          centered
        >
          <Button id="pop-close1" onClick={handleModal}>
            &times;
          </Button>
          <Modal.Header id="header">
            <img
              id="popImg"
              src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1718344862/logo_l667oi.png"
              alt="img-responsive"
            />
          </Modal.Header>
          <div className="model-body">
            <ul className="llist">
              {/* <li key="1">
                Once your order has processed, the link to your rewards card
                will be EMAILED to you by the Virtual Rewards Center.
              </li> */}
              <li key="1">
                1) For Stanley Cups your gift will arrive within 2-4 weeks.{" "}
                <br />
                2) For MasterCard Reward Expect an email(s) within 5 business
                days from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_REWARD}>
                  {process.env.REACT_APP_DESC_REWARD}
                </a>
                , <br />
                <a href={"mailto:" + process.env.REACT_APP_PREP}>
                  {process.env.REACT_APP_PREP}
                </a>{" "}
                or{" "}
                <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
                  {process.env.REACT_APP_MARKET_PLACE}
                </a>{" "}
                <br />
                Please add these email addresses to your safe senders list in
                your email setting.
                <br />
                Once you receive your email(s) you may use the reward virtually
                or request plastic cards to be mailed.
              </li>
              <li key="2">
                You will need to click that link to finalize your order and
                select your preferred delivery method.
              </li>
              <li key="3">
                You will need to finalize your order by following the
                instructions in that email from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL_POPUP}>
                  {process.env.REACT_APP_DESC_EMAIL_POPUP}
                </a>
              </li>
              <br />
              <p>
                <b>
                  PLEASE NOTE: You only have 60 days to activate your card.
                  Check your inbox, junk and spam and contact our customer
                  service line if you haven't received the email within the
                  week.
                </b>
              </p>
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ContentPopup;
