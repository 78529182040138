import React from "react";

function SampBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img
          src="https://images-cloud.s3.ap-south-1.amazonaws.com/mathis-notifi.png"
          alt="GW-notifi.png"
          width="55"
          height="66"
        />
      </div>
      <div className="col-md-11 mt-lg-4" style={{ fontFamily: "Arial" }}>
        Please make sure to monitor your SPAM and JUNK folders as well.
      </div>
    </div>
  );
}
export default SampBox;
