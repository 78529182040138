import React from "react";
import Pagination from "react-js-pagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import Swal from "sweetalert2";
import $ from "jquery";
import { useSelector } from "react-redux";

export default function ManageRegisteredUsersByRsa() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");
  const [filteredData, setFilteredData] = useState([]);
  const { id } = useParams();
  const adminList = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.listRegisteredUsersByRsa(id);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            store_location: value.store_location,
            rsaName: value.rsa_fname + " " + value.rsa_lname,
            customerEmail: value.cust_email,
            customer_F: value.cust_fname,
            customer_L: value.cust_lname,
            employee_F: value.sales_fname,
            employee_L: value.sales_lname,
            phone: value.phone,
            date: value.date,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      adminList();
    }
  }, [adminAccessToken]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.store_location.toString().toLowerCase().includes(searchQuery) ||
          item.customerEmail.toString().toLowerCase().includes(searchQuery) ||
          item.customer_F.toString().toLowerCase().includes(searchQuery) ||
          item.customer_L.toString().toLowerCase().includes(searchQuery) ||
          item.employee_F.toString().toLowerCase().includes(searchQuery) ||
          item.phone.toString().toLowerCase().includes(searchQuery) ||
          item.employee_L.toString().toLowerCase().includes(searchQuery) ||
          item.date.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("employee_F")}
                className={getClassNamesFor("employee_F")}
              >
                Employee First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("employee_L")}
                className={getClassNamesFor("employee_L")}
              >
                Employee Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_location")}
                className={getClassNamesFor("store_location")}
              >
                Store Identifier
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customerEmail")}
                className={getClassNamesFor("customerEmail")}
              >
                Customer Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_F")}
                className={getClassNamesFor("customer_F")}
              >
                Customer First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_L")}
                className={getClassNamesFor("customer_L")}
              >
                Customer Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Customer Phone#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{el.employee_F}</td>
                  <td>{el.employee_L}</td>
                  <td>{el.store_location}</td>
                  <td>{el.customerEmail}</td>
                  <td>{el.customer_F}</td>
                  <td>{el.customer_L}</td>
                  <td>{el.phone}</td>
                  <td>{el.date}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Registered Users By{" "}
                {tableData[0]?.rsaName ? tableData[0]?.rsaName : "RSA"}
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  Back
                </button>
              </h5>
            </div>

            <div className="card-body">
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
