import { React, useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import RegisterService from "../../services/register.service";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { setPassword } from "../../schema";
import Swal from "sweetalert2";

export default function TokenCheck() {
  const [loading, setLoading] = useState("checking");
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const { token } = useParams();
  useEffect(() => {
    if (token) {
      const checkToken = async () => {
        try {
          const response = await RegisterService.checkLoginToken(token);
          if (response.status === 200) {
            setLoading("ok");
          }
        } catch (err) {
          console.log(err);
          setLoading("fail");

          Swal.fire({
            title: "Link Expired",
            text:
              err?.response?.data?.response &&
              typeof err?.response?.data?.response === "string"
                ? err?.response?.data?.response
                : err?.response?.data?.message &&
                  typeof err?.response?.data?.message === "string"
                ? err?.response?.data?.message
                : "Something went wrong",
            icon: "error",
            iconColor: "#e04f5d",
            showCancelButton: false,
            confirmButtonColor: "#dc3545",

            confirmButtonText: "Ok",
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.location.href = "/register-login";
            }
          });
        }
      };
      checkToken();
    } else {
      window.location.href = "/register-login";
    }
  }, []);

  const passWordSet = async (values) => {
    try {
      setSubmitted(true);
      const response = await RegisterService.setPassword(values);
      if (response.status === 200) {
        toast.success("Password Saved!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSubmitted(false);
        setTimeout(() => {
          navigate("/register-login", { replace: true });
        }, 1000);
      }
    } catch (err) {
      setSubmitted(false);
      toast.error("Error in setting password!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      con_password: "",
      token: token,
    },
    validationSchema: setPassword,
    onSubmit: (values) => {
      passWordSet(values);
    },
  });
  return (
    <>
      <Helmet>
        <title>
          {loading === "checking"
            ? " Checking..."
            : loading == "ok"
            ? "Set Password"
            : "Invalid Link"}
        </title>
      </Helmet>

      {loading === "checking" ? (
        <div className="fix-white">
          <div className={`loader in `}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
      ) : loading == "ok" ? (
        <div className="login-main">
          <div className="container">
            <div className="box-outer">
              <div className="login-box">
                <div className="mws-login-lock">
                  <i className="bi bi-lock-fill"></i>
                </div>
                <div className="col-lg-12 login-key">
                  <img
                    src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1718344862/logo_l667oi.png"
                    alt=""
                  />
                </div>

                <div className="col-lg-12 login-form">
                  <div className="col-lg-12 login-form">
                    {(errors.con_password && touched.con_password) ||
                    (errors.password && touched.password) ? (
                      <div className="alert border-0 alert-danger alert-dismissible fade show  py-2">
                        <div className="d-flex align-items-center">
                          <div className="text-danger">
                            {errors.password && touched.password
                              ? errors.password
                              : errors.con_password}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <form onSubmit={handleSubmit} noValidate>
                      <div className="form-group">
                        <label className="form-control-label">PASSWORD</label>
                        <input
                          type="password"
                          className="form-control"
                          onChange={handleChange}
                          name="password"
                          value={values.password || ""}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">
                          CONFIRM PASSWORD
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          onChange={handleChange}
                          name="con_password"
                          value={values.con_password || ""}
                        />
                      </div>

                      <div className="col-lg-12 loginbttm">
                        <button
                          type="submit"
                          className="btn btn-outline-primary"
                        >
                          SET
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-3 col-md-2"></div>
              </div>
            </div>
          </div>
          <div className={`loader ${submitted ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
      ) : null}
    </>
  );
}
