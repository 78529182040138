import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "../admin/login/login.css";

import { Helmet } from "react-helmet";
import { forgotPass } from "../../schema";
import RegisterService from "../../services/register.service";
import { useSelector } from "react-redux";

export default function RegisterForgot() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const state = useSelector((state) => state.stateVals);
  const { rsaAccessToken, rsa_id } = state;

  useEffect(() => {
    if ((rsaAccessToken, rsa_id)) {
      navigate("/register", { replace: true });
    } else {
      setShow(true);
    }
  }, [rsaAccessToken, rsa_id]);

  const loginCheck = async (values) => {
    setLoading(true);
    try {
      const response = await RegisterService.forgotRsa(values);

      toast.success(response?.data?.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: forgotPass,
    onSubmit: (values, action) => {
      loginCheck(values);
    },
  });

  return show ? (
    <div className="login-main">
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div className="container">
        <div className="box-outer">
          <div className="login-box">
            <div className="mws-login-lock">
              <i className="bi bi-lock-fill"></i>
            </div>
            <div className="col-lg-12 login-key">
              <img
                src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1718344862/logo_l667oi.png"
                alt=""
              />
            </div>

            <div className="col-lg-12 login-form">
              <div className="col-lg-12 login-form">
                {errors.username && touched.username ? (
                  <div className="alert border-0 alert-danger alert-dismissible fade show  py-2">
                    <div className="d-flex align-items-center">
                      <div className="text-danger">{errors.username}</div>
                    </div>
                  </div>
                ) : null}

                <form onSubmit={handleSubmit} noValidate>
                  <div className="form-group">
                    <label className="form-control-label">EMAIL</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      name="username"
                      value={values.username || ""}
                    />
                  </div>

                  <div className="col-lg-12 loginbttm text-center">
                    <button
                      type="submit"
                      className="btn btn-outline-primary mb-3"
                    >
                      RESET
                    </button>
                    <br />
                    <Link to="/register-login" className="text-white">
                      Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  ) : (
    <div className="fix-white"></div>
  );
}
